import create from 'zustand'
import constants from '@/constants'

const useStore = create((set) => ({
  stage: 0,
  setStage: (num) => set(() => ({ stage: num })),
  model: -1,
  setModel: (num) => set(() => ({ model: num })),

  // Fallback
  fallback: window.location.search === '?fallback',
  setFallback: (bool) => set(() => ({ fallback: bool })),

  // Stages settings
  stage0: {
    bgColor1: '#40437a',
    bgColor2: '#2c2a32',
    cameraPos: {
      x: 0,
      y: 0,
    },
  },
  stage1: {
    bgColor1: '#40437a',
    bgColor2: '#2c2a32',
    cameraPos: {
      x: 0,
      y: 0,
    },
  },
  stage2: {
    bgColor1: '#292637',
    bgColor2: '#263b32',
    cameraPos: {
      x: 15,
      y: 0,
    },
  },
  stage3: {
    bgColor1: '#3e4177',
    bgColor2: '#2c2a32',
    cameraPos: {
      x: 0,
      y: 10,
    },
  },

  // Models Settings
  model0: {
    // file: `${constants.THEME_DIR}/models/blob3.glb`,
    file: `${constants.THEME_DIR}/models/boiler-draco.glb`,
    bgColor1: '#25252f',
    bgColor2: '#282640',
    circleColor1: '#3f4ff7',
    circleColor2: '#3f4ff7',
    modelColor: '#3f4ff7',
    modelColorB: '#b38536',
    metalness: 0.5,
    roughness: 0.35,
    metalnessB: 0.5,
    roughnessB: 0.35,
  },
  model1: {
    file: `${constants.THEME_DIR}/models/cpu-draco.glb`,
    bgColor1: '#1d464a',
    bgColor2: '#273630',
    circleColor1: '#188155',
    circleColor2: '#188155',
    modelColor: '#188155',
    metalness: 0.5,
    roughness: 0.35,
  },
  model2: {
    file: `${constants.THEME_DIR}/models/proteine-draco.glb`,
    bgColor1: '#aa4f39',
    bgColor2: '#b36736',
    circleColor1: '#a99e34',
    circleColor2: '#a99e34',
    modelColor: '#de9d35', // de9d35
    metalness: 0.5,
    roughness: 0.35,
  },
}))

export default useStore
