import { useEffect, useCallback, Suspense } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import MouseContext from '@/context/mouse'
import LoadingContext from '@/context/loading'
import Handlers from '@/components/Handlers'
import BannerIE11 from '@/components/BannerIE11'
import Routes from '@/router'
import Layout from '@/layout'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/options'
import * as navActions from '@/actions/nav'
import * as contentActions from '@/actions/content'
import useViewport from '@/hooks/useViewport'
import { theme } from './style'

const App = () => {
  const routes = useSelector((state) => state.routing.routes)

  const { vw, vh, vwfixed, vhfixed } = useViewport()

  useEffect(() => {
    document.documentElement.style.setProperty('--vw', `${vw}px`)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
    document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)
  }, [vw, vh, vwfixed, vhfixed])
  // document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
  // document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const receiveRouting = useCallback((data) => dispatch(routingActions.receiveRouting(data)), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])
  const fetchMenu = useCallback((name) => dispatch(navActions.fetchMenu(name)), [dispatch])
  const fetchCpts = useCallback((cpt, page) => dispatch(contentActions.fetchCpts(cpt, page)), [dispatch])

  useEffect(() => {
    if (window.routing) {
      receiveRouting(window.routing)
    } else {
      fetchRouting()
    }
    fetchOptions()
    fetchMenu('main_menu')
    fetchMenu('extra_menu')
    fetchMenu('header_menu')
    fetchMenu('footer_menu')
    fetchCpts('_cty_people', 100)
  }, [])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <LoadingContext>
            <MouseContext>
              <ScrollbarContext>
                <Layout>
                  {theme.detect.isIE11 && <BannerIE11 />}
                  <Suspense fallback={<div />}>
                    <Routes routes={routes} />
                  </Suspense>
                </Layout>
              </ScrollbarContext>
            </MouseContext>
          </LoadingContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
